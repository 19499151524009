<!--
 * @Author: lbh
 * @Date: 2023-07-29 11:07:43
 * @LastEditors: lbh
 * @LastEditTime: 2023-08-05 11:26:21
 * @Description: 蜂窩模型
-->
<template>
  <div class="cellular_model_box">
    <div class="setting_box">

      <el-button @click="plusChild">新加</el-button>
    </div>

    <div
      id="container"
      ref="container"
    >
      <!-- 格子 -->
      <div
        v-for="(item,index) in allGrid"
        :key="index"
        class="grid"
        :style="{left:`${item.x}rem`,top:`${item.y}rem`}"
      >{{index}}</div>
      <div
        v-for="item,index in child"
        :key="`${index}_c`"
        class="draw_box"
        :class="`draw_box_${index}`"
      >
        <i
          class="el-icon-close"
          @click="reduceChild(index)"
        ></i>
        {{index}}
      </div>
    </div>
  </div>
</template>

<script>
import { gsap, TweenLite, Draggable } from "gsap/all";
gsap.registerPlugin(Draggable);
export default {
  data () {
    return {
      gridRows: 4,
      gridColumns: 10,
      allGrid: [],
      child: []
    };
  },
  mounted () {
    this.initGrid();
  },
  methods: {
    initGrid () {
      const { gridRows, gridColumns } = this;
      let gridWidth = 130,
        gridWidth2 = gridWidth / 2,
        gridHeight = 150,
        padding = 4,
        i, x, y, p = 0,
        gridArr = [];
      for (i = 0; i < gridRows * gridColumns; i++) {
        let count = Math.floor(i / gridColumns);
        y = Math.floor(i / gridColumns) * gridHeight;
        x = (i * gridWidth) % (gridColumns * gridWidth)
        if (i % gridColumns == 0) {
          p = 0;
        } else {
          p += padding;
        }
        x += p;
        if (count % 2 == 1) x += (gridWidth2 + 2);
        if (count > 0) y -= count * 34
        x = x / 192;
        y = y / 192;
        gridArr.push({
          x: x,
          y: y
        })
      }
      this.allGrid = gridArr
      TweenLite.set(this.$refs.container, { height: gridRows * gridHeight + 1, width: gridColumns * (gridWidth + padding) + 1 + gridWidth2 });

    },
    plusChild () {
      let json = {
        x: 0,
        y: 0
      }
      let child = this.child;
      child.push(json);
      this.child = child;
      this.$nextTick(() => {
        this.initChild();
      })
    },
    reduceChild (index) {
      let child = this.child;
      child.splice(index, 1);
      this.child = child;
    },
    initChild () {

      const { gridRows, gridColumns } = this;

      let gridWidth = 130,
        gridWidth2 = gridWidth / 2,
        gridHeight = 150,
        padding = 4,
        i, x, y, p = 0,
        gridArr = [];
      let nowCount = 0;

      // let child = this.child;
      // child.forEach((item, index) => {
      //   console.log(index, item.x, item.y);
      //   // 设置 属性
      //   TweenLite.set(`.draw_box_${index}`, { width: gridWidth, height: gridHeight, transform: `translate3d('${item.x}px', '${item.y}px', '0px')` });
      // })


      let _this = this;
      Draggable.create(".draw_box", {
        bounds: '#container',
        edgeResistance: 0.65,
        type: "x,y",
        inertia: true,
        snap: {
          x: function (endValue) {
            let count = Math.round(endValue / (gridWidth + padding));
            let w = count * gridWidth;
            w += ((count) * 4);
            if (nowCount % 2 == 1) {
              w += 2;
              w += gridWidth2;
            }
            return w;
          },
          y: function (endValue) {
            // 拿到 第幾行
            let count = Math.round(endValue / 116);
            if (count >= gridRows) count = gridRows - 1;
            // 計算 y 坐標
            let h = count * gridHeight;
            let d = 34 * count;
            h -= d;
            return h;
          },

        },
        onDrag: function () {
          let y = this.y;
          let x = this.x;
          console.log(x, y);
          // let count = Math.round(y / 116);
          // if (count >= gridRows) count = gridRows - 1;
          // nowCount = count;
        },
        onDragEnd: function () {
          console.log(this.endX);
          console.log(this.endY);

          window.aaaa = this.target
          this.target.style.transform = `translate3d(${this.endX / 192}rem, ${this.endY / 192}rem, 0px)`;
          console.log(this.target.classList);
          let class_ = this.target.classList[1];
          _this.child[class_.split('_')[2]] = {
            x: this.endX, y: this.endY
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.cellular_model_box {
  #container {
    position: relative;
    .grid {
      position: absolute;
      width: 130px;
      height: 150px;
      clip-path: polygon(
        0px 25%,
        50% 0px,
        100% 25%,
        100% 75%,
        50% 100%,
        0px 75%
      );
      background-color: #efefef;
    }

    .draw_box {
      position: absolute;
      width: 130px;
      height: 150px;
      clip-path: polygon(
        0px 25%,
        50% 0px,
        100% 25%,
        100% 75%,
        50% 100%,
        0px 75%
      );
      background-color: aquamarine;
    }
  }
}
</style>